import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../theme';

const Panel = styled.div`
  background-color: ${props => {
    switch (props.type) {
      case 'warning':
        return theme.lightYellow;
      case 'critical':
        return theme.lightRed;
      case 'optional':
        return theme.lightGreen;
      default:
        return theme.lightGrey;
    }
  }};
  border-radius: 3px;
  padding: ${theme.sitePadding};
  margin: 1rem;
`;

const PanelBody = styled.div``;

const PanelHeading = styled.h3`
  margin: 0 0 0.5rem 0;
  text-decoration: none;
  color: black;
`;

const PanelWrapper = ({ title, children, type }) => (
  <Panel type={type}>
    {title ? <PanelHeading>{title}</PanelHeading> : null}
    <PanelBody>{children}</PanelBody>
  </Panel>
);

PanelWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element.node,
  type: PropTypes.string.isRequired,
};

PanelWrapper.defaultProps = {
  title: undefined,
};

export default PanelWrapper;
