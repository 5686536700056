import React from 'react';
import RehypeReact from 'rehype-react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Panel from '../components/panel';
import DocLink from '../components/doc_link';
import Layout from '../components/layout';
import Video from '../components/styled_video';

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: {
    panel: Panel,
    doclink: DocLink,
    styledvideo: Video,
  },
}).Compiler;

const Article = ({ data, location }) => {
  const article = data.markdownRemark;
  const { pageTitle } = data.markdownRemark.frontmatter;
  const fullPageTitle = pageTitle
    ? [pageTitle, 'Docs', 'GorillaStack'].join(' :: ')
    : 'GorillaStack';
  return (
    <Layout pageTitle={fullPageTitle} location={location}>
      <div>
        {/* without rehype-react <div dangerouslySetInnerHTML={{ __html: article.html }}/> */}
        {renderAst(article.htmlAst)}
      </div>
    </Layout>
  );
};

Article.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.string,
      htmlAst: PropTypes.string,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default Article;

export const query = graphql`
  query ArticleQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      # html
      htmlAst
      frontmatter {
        title
        pageTitle
      }
    }
  }
`;
