/* eslint-disable jsx-a11y/media-has-caption */
// at time of writing, compenent is used of one video only
// which does not contain audio. Reconsider eslint-disable
// is adding video with audio
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Video = styled.video`
  max-width: 960px;
`;

const VideoWrapper = ({ src, type }) => (
  <Video controls>
    <source src={src} type={`video/${type}`} />
    <p>
      Your browser doesn&apos;t support HTML video. Here is a&nbsp;
      <a href={src}>link to the video</a>
      &nbsp;instead.
    </p>
  </Video>
);
VideoWrapper.propTypes = {
  src: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default VideoWrapper;
