import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// import backImg from '../images/back.png';
// import nextImg from '../images/next.png';

const DocLink = styled.div`
  display: block;
  float: ${props => (props.direction === 'back' ? 'left' : 'right')};
  margin-left: ${props => (props.direction === 'back' ? '5' : '0')}px;
  margin-right: ${props => (props.direction === 'back' ? '0' : '5')}px;
  margin-top: 45px;
  margin-bottom: 40px;
  a {
    color: #2d3c78;
    text-decoration: none;
    text-shadow: none;
    background-image: none;
    font-weight: 600;
    text-transform: uppercase;
    background-color: white;
    padding: 7px 13px;
    min-width: 70px;
    border: 2px solid #2d3c78;
    border-radius: 3px;
    font-size: 13px;
  }
`;
DocLink.propTypes = {
  direction: PropTypes.string.isRequired,
  float: PropTypes.string.isRequired,
};

const DocLinkWrapper = ({ direction, href }) => (
  <DocLink direction={direction}>
    <a href={href}>{direction === 'back' ? <span>Back</span> : <span>Next</span>}</a>
  </DocLink>
);

DocLinkWrapper.propTypes = {
  direction: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default DocLinkWrapper;
